<template>
	<div>
		<div class="padding-b-sm">
			<el-button
				type="primary"
				icon="el-icon-plus"
				size="mini"
				@click="showAddStudent"
				>选择学员</el-button
			>
		</div>
		<div class="student padding-sm">
			<div style="color: #606266; line-height: 30px">
				<span class="fl-l"> 已选学员：</span>
				<el-button
					class="fl-r"
					type="primary"
					icon="el-icon-delete"
					size="mini"
					@click="clearAll"
					>清空</el-button
				>
				<div class="clear"></div>
			</div>

			<div class="margin-t-sm">
				<el-tag
					type="success"
					closable
					class="margin-b-sm margin-r-sm"
					effect="plain"
					:key="student.id"
					v-for="student in selectStudent"
					@close="subStudent(student)"
				>
					<div class="fl-l" style="padding: 5px">
						<div style="display: flex; align-items: center">
							<el-avatar
								shape="square"
								size="small"
								:src="student.user_name_avatar_url"
							></el-avatar>
							<span class="margin-l-sm">{{ student.user_name }}</span>
						</div>
					</div>
				</el-tag>
			</div>
		</div>

		<div class="margin-t-sm">
			<el-form
				ref="form"
				:rules="rules"
				:model="form"
				label-width="100px"
				label-position="left"
			>
				<el-form-item label="通知类型:" prop="type">
					<el-select
						size="mini"
						v-model="form.type"
						clearable
						placeholder="选择通知类型"
					>
						<el-option
							v-for="item in notificationType"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						>
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="老师名称:" prop="name">
					<el-input
						v-model="form.name"
						style="width: 195px"
						placeholder="请输入老师名称"
						clearable
					></el-input>
				</el-form-item>

				<el-form-item label="跳转小程序：" label-position="left" class="input">
					<el-switch
						v-model="form.toApplet"
						:active-value="1"
						:inactive-value="0"
					>
					</el-switch>
					<span style="margin-left: 5px; font-size: 12px">{{
						form.toApplet === 0 ? '不开启' : '开启'
					}}</span>

					<el-tooltip
						class="item"
						effect="dark"
						content="开启后消息将跳转到指定的小程序页面"
						placement="top"
					>
						<span
							class="margin-l-sm el-icon-question"
							style="color: #909399; cursor: pointer"
						>
						</span>
					</el-tooltip>
				</el-form-item>

				<el-form-item
					label="小程序页面:"
					v-if="form.toApplet === 1"
					prop="path"
				>
					<el-input
						v-model="form.path"
						style="width: 195px"
						placeholder="请输入小程序页面"
						clearable
					></el-input>
				</el-form-item>

				<el-form-item label="通知内容：" prop="content">
					<el-input
						type="textarea"
						v-model="form.content"
						show-word-limit
						maxlength="150"
						:rows="20"
						resize="none"
					></el-input>
				</el-form-item>
			</el-form>

			<div style="display: flex; justify-content: center; margin-top: 20px">
				<el-button
					type="primary"
					size="mini"
					@click="sendNotify"
					:loading="btnLoading"
					>发送通知</el-button
				>
			</div>
		</div>

		<el-dialog
			title="选择学员"
			append-to-body
			:show-close="false"
			:close-on-press-escape="false"
			:close-on-click-modal="false"
			:visible.sync="showAddStudentDialog"
			width="50%"
		>
			<div>
				<div>课堂学员：</div>
				<div class="margin-t-sm" v-loading="loading">
					<el-tag
						class="margin-b-sm margin-r-sm"
						effect="plain"
						:type="student.status === 1 ? '' : 'info'"
						:key="student.id"
						v-for="student in courseStudents"
					>
						<div style="display: flex; align-items: center; padding: 5px 0">
							<el-avatar
								shape="square"
								size="small"
								:src="student.user_name_avatar_url"
							></el-avatar>
							<span class="margin-l-sm">{{ student.user_name }}</span>
							<span
								class="margin-l-sm el-icon-plus icon-hover"
								@click="selectStudents(student)"
							></span>
						</div>
					</el-tag>
				</div>

				<div class="table-batch">
					<span
						><el-button type="primary" size="mini" @click="selectAll"
							>全选</el-button
						></span
					>
					<span class="fl-r padding-b-sm">
						<el-pagination
							small
							background
							:current-page="page"
							:page-sizes="[1000, 2000, 5000]"
							:page-size="pageSize"
							layout="total, sizes, prev, pager, next, jumper"
							@size-change="setPageSize"
							@current-change="setPage"
							:total="total"
						>
						</el-pagination>
					</span>
					<div style="clear: both"></div>
				</div>

				<div>
					<p class="margin-t-sm margin-b-sm">已选学员</p>
					<div
						style="padding: 10px 10px 0 10px; border-radius: 10px"
						:style="selectStudent.length > 0 ? 'border:1px solid #eee' : ''"
					>
						<el-tag
							type="success"
							closable
							class="margin-b-sm margin-r-sm"
							effect="plain"
							:key="student.id"
							v-for="student in selectStudent"
							@close="subStudent(student)"
						>
							<div class="fl-l">
								<div style="display: flex; align-items: center; padding: 5px 0">
									<el-avatar
										shape="square"
										size="small"
										:src="student.user_name_avatar_url"
									></el-avatar>
									<span class="margin-l-sm">{{ student.user_name }}</span>
								</div>
							</div>
						</el-tag>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="hideAddStudent" size="mini">取 消</el-button>
				<el-button type="primary" @click="hideAddStudent" size="mini"
					>确 定</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'notification',
	data() {
		return {
			loading: false,
			btnLoading: false,
			showAddStudentDialog: false,
			value: '',
			form: {
				courseId: 0,
				type: 1,
				name: '',
				toApplet: 0,
				path: '',
				content: '',
				userIds: []
			},
			rules: {
				type: [
					{
						type: 'number',
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}
				],
				name: [
					{
						type: 'string',
						required: true,
						message: '输入老师名称',
						trigger: 'blur'
					}
				],
				path: [
					{ required: true, message: '请输入小程序页面路径', trigger: 'blur' }
				],
				content: [
					{
						type: 'string',
						required: true,
						message: '请输入通知内容',
						trigger: 'blur'
					}
				]
			},
			selectStudent: [],
			courseStudents: [],
			notificationType: [
				{
					id: 1,
					name: '班级通知'
				},
				{
					id: 2,
					name: '老师请假通知'
				}
			],
			page: 1,
			pageSize: 10,
			total: 0
		}
	},
	methods: {
		...mapActions('course', ['getCourseStudent', 'sendCourseNotify']),
		subStudent(student) {
			this.selectStudent.splice(this.selectStudent.indexOf(student), 1)
		},
		validateForm(formName) {
			let validate = false
			this.$refs[formName].validate(valid => {
				if (valid) {
					validate = true
					return true
				} else {
					validate = false
					return false
				}
			})
			return validate
		},
		clearAll() {
			this.selectStudent = []
		},
		selectAll() {
			this.courseStudents.forEach(student => {
				if (
					JSON.stringify(this.selectStudent).indexOf(JSON.stringify(student)) ==
					-1
				) {
					this.selectStudent.push(student)
				}
			})
		},
		showAddStudent() {
			this.showAddStudentDialog = true
		},
		hideAddStudent() {
			this.showAddStudentDialog = false
		},
		selectStudents(student) {
			if (
				JSON.stringify(this.selectStudent).indexOf(JSON.stringify(student)) ==
				-1
			) {
				this.selectStudent.push(student)
			}
		},
		getCourseStudentList() {
			this.loading = true
			let data = {
				id: this.id,
				page: this.page,
				pageSize: this.pageSize,
				status: 1
			}
			this.getCourseStudent(data).then(res => {
				if (res.ret == 0) {
					this.total = res.data.total
					this.courseStudents = res.data.list
					this.loading = false
				}
			})
		},
		setPageSize(pageSize) {
			this.pageSize = pageSize
			this.getCourseStudentList()
		},
		setPage(page) {
			this.page = page
			this.getCourseStudentList()
		},
		sendNotify() {
			if (!this.validateForm('form')) {
				this.$message.error('请填写必填选项！')
				return false
			}

			this.selectStudent.forEach(student => {
				this.form.userIds.push(student.user_id)
			})

			if (this.form.userIds.length === 0) {
				this.$message.error('请先选择需要通知的学员！')
				return
			}
			this.btnLoading = true

			console.log(this.form)
			this.sendCourseNotify(this.form).then(res => {
				if (res.ret === 0) {
					this.$message.success('发送成功！')
				}
				this.btnLoading = false
			})
		}
	},
	mounted() {
		this.id = this.$route.params.id
		this.form.courseId = this.id
		this.getCourseStudentList()
	}
}
</script>

<style>
.student {
	border: 1px solid #eee;
}
.icon-hover {
	cursor: pointer;
	font-size: 14px;
}
.icon-hover:hover:before {
	font-size: 14px;
	content: '\e7a0';
}
.el-tag {
	height: auto;
	line-height: auto;
}
.el-tag .el-icon-close {
	top: 5px;
}
</style>
